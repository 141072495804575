import './privacy.css';
function PrivacyPolicy() {
    return (
        <> <div className="privacy-container">
            <h1>Privacy Policy for Pumkee</h1>
            <h2>1. Introduction</h2>
            <p>Our Grocery Application “PUMKEE” operates a multi-faceted grocery application consisting of
                a customer app, vendor app, and delivery app collectively referred to as the "Service".
                This page informs you of our policies regarding the collection, use, and disclosure of personal
                data when you use our Service and the choices you have associated with that data.
                We use your data to provide and improve the Service. By using the Service, you agree to the
                collection and use of information under this policy.
            </p>
            <h2>2. Information Collection and Use</h2>
            <h3>Personal Data:</h3>
            <p>When you register on our customer app or place an order through the app, we may collect
                personal identification information from you, including your name, email address, phone
                number, and delivery address. Additionally, we require location access to fetch nearby stores.
            </p>
            <h3>Non-personal Data:</h3>
            <p>Alongside personal data, we may gather non-personal identification information about your
                interaction with our app. This encompasses technical details like device type, operating system,
                and browsing behavior.
            </p>
            <h4>Vendor App:</h4>
            <h5>Personal Data:</h5>
            <p>Vendors who register on our vendor app may be required to provide personal
                information including but not limited to name, contact information, business
                details, and banking information for payment processing.
            </p>
            <h5>Non-personal Data:</h5>
            <p>We may collect non-personal identification information about vendors such as
                transaction history, sales data, and inventory management information.
            </p>
            <h4>Delivery App:</h4>
            <h5>Personal Data:</h5>
            <p>Delivery personnel registering on our delivery app may be required to provide
                personal information including name, contact details, vehicle information, and
                location data.
            </p>
            <h5>Non-personal Data:</h5>
            <p>We may collect non-personal identification information about delivery personnel
                such as delivery history, route preferences, and performance metrics.
            </p>
            <h2>3. Information Protection</h2>
            <p>We adopt appropriate data collection, storage, and processing practices and security measures to
                protect against unauthorized access, alteration, disclosure, or destruction of personal information
                stored on our Service.
            </p>
            <h2>4. Information Sharing</h2>
            <p>We do not sell, trade, or rent Users' personal identification information to others. We may share
                generic aggregated demographic information not linked to any personal identification
                information regarding visitors and users with our business partners, trusted affiliates, and
                advertisers for the purposes outlined above.
            </p>
            <h2>5. Compliance with Laws</h2>
            <p>We will disclose any information we collect, use, or share when required to do so by law or
                subpoena or if we believe that such action is necessary to comply with the law and the
                reasonable requests of law enforcement or to protect the security or integrity of our Service.
            </p>
            <h2>6. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by
                posting the new Privacy Policy on this page.
            </p>
            <h2>7. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:contact@fuzionest.com">contact@fuzionest.com</a></p>
            <p>By using the Service, you signify your acceptance of this policy. If you do not agree to this
                policy, please do not use our Service.
            </p>
            </div>
        </>
    )
}
export default PrivacyPolicy