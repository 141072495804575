import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Teams&conditions.css';
function TermsAndConditions() {
    useEffect(() => {
        const termsLastElement = document.querySelector(".terms-and-conditions").lastElementChild;
        const scrollToBottom = document.querySelector(".scroll-to-bottom");
        const acceptButton = document.querySelector(".accept-button");

        scrollToBottom.addEventListener("click", function (e) {
            termsLastElement.scrollIntoView({
                block: "start",
                behavior: "smooth",
                inline: "nearest"
            });
        });

        function obCallback(payload) {
            if (payload[0].isIntersecting) {
                scrollToBottom.setAttribute("aria-hidden", true);
                acceptButton.setAttribute("aria-hidden", false);
                observer.unobserve(termsLastElement);
            }
        }

        const observer = new IntersectionObserver(obCallback, { root: document.querySelector(".terms-and-conditions"), threshold: 0.1 });

        observer.observe(termsLastElement);

        // Cleanup function
        return () => {
            scrollToBottom.removeEventListener("click", function (e) {
                termsLastElement.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                    inline: "nearest"
                });
            });
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <article class="terms-and-conditions">
                <h1>Terms and Conditions</h1>
                <p>Welcome to Our grocery application“PUMKEE”! These terms and conditions outline the rules
                    and regulations for the use of our services.
                    By accessing and using our grocery application, we assume you accept these terms and
                    conditions. If you do not agree to abide by all of the terms and conditions stated herein, please
                    refrain from using our application.
                    The following terminology applies to these Terms and Conditions, Privacy Statement, and
                    Disclaimer Notice and all Agreements: "User", "You", and "You're" refer to you, the person
                    accessing this application and accepting the Company's terms and conditions. "The Company",
                    "Ourselves", "We", "Our", and "Us" refer to our Company Fuzionest Private Limited. "Party",
                    "Parties", or "Us" refer to both the User and ourselves. All terms refer to the offer, acceptance,
                    and consideration of payment necessary to undertake the process of our assistance to the User
                    most appropriately for the express purpose of meeting the User's needs concerning the provision
                    of the Company's stated services, under and subject to, prevailing law.</p>

                <h3>Privacy and Personal Data:</h3>
                <p>
                    We may collect personal identification information from Users such as name, email address,
                    phone number, and delivery address when they register on our grocery application or place an
                    order through the app.
                    We require location access to fetch nearby stores when you use our application.
                    We may also collect non-personal identification information about Users whenever they interact
                    with our application. This may include technical information such as device type, operating
                    system, and browsing behavior.
                </p>
                <h3>License</h3>
                <p>
                    Unless otherwise stated, our company and its licensors own the intellectual property rights for all
                    material on our grocery application. All intellectual property rights are reserved.
                </p>
                <p>You must not:</p>
                <ul>
                    <li>Republish material from our grocery application</li>
                    <li>Sell, rent, or sub-license material from our application</li>
                    <li>Reproduce, duplicate, or copy material from our application</li>
                    <li>Redistribute content from our application</li>
                </ul>





                <h2>Comments and Feedback:</h2>
                <p>We welcome your comments and feedback on our grocery application. However, we reserve the
                    right to monitor all comments and to remove any comments that can be considered inappropriate,
                    offensive, or in breach of these Terms and Conditions.</p>
                <p>By posting comments or feedback on our application, you warrant and represent that:</p>
                <ul>
                    <li>You are entitled to post comments or feedback and have all necessary licenses and
                        consents to do so.</li>
                    <li>The comments or feedback do not invade any intellectual property right of any third
                        party.</li>
                    <li>The comments or feedback do not contain any defamatory, libelous, offensive, indecent,
                        or otherwise unlawful material.</li>

                </ul>


                <h3>Disclaimer:</h3>
                <p>
                    We do not ensure that the information on our grocery application is correct, and we do not
                    warrant its completeness or accuracy.
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties,
                    and conditions relating to our grocery application and the use of this application.
                    As long as our application and the information and services provided are free of charge, we will
                    not be liable for any loss or damage of any nature.
                    By using our grocery application, you agree to abide by these terms and conditions. If you do not
                    agree to these terms, please refrain from using our application.
                    This Agreement shall begin on the date hereof and shall continue in effect unless terminated by
                    either party as per the termination clause.
                </p>

            </article>
            <div className="term-buttons-container">
                <button className="scroll-to-bottom">
                    <svg
                        width="20"
                        height="11"
                        xmlns="http://www.w3.org/2000/svg"
                        title="Go to bottom to accept terms and conditions"
                    >
                        <path d="M0 0 L10 10 L20 0 Z" fill="black" />


                    </svg>
                </button>
                <div class="button-container">
                    <form method="POST" name="terms-and-conditions-form" action="#">

                        <Link to={'/'}><button type="submit" class="accept-button" aria-hidden="true" aria-label="Accept terms and conditions">Accept</button>
                        </Link> </form>
                </div>
            </div>
        </>
    );
}

export default TermsAndConditions;
