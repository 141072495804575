import './grocery.css';
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <>
            <footer class="flex">
                <section class="flex_content padding_2x">
                    <a href="#"><i class="fa fa-facebook"></i></a>
                    <a href="#"><i class="fa fa-instagram"></i></a>
                    <a href="#"><i class="fa fa-linkedin"></i></a>
                    <a href="#"><i class="fa fa-youtube"></i></a>
                </section>
                <section class="flex_content">
                    <Link to={'/'}><a href="#">Home</a></Link>
                    <a href="https://fuzionest.com/?gad_source=1&gclid=CjwKCAiAloavBhBOEiwAbtAJO_Ucq2KaGwpbM92COBaqsfAhftZvH2Huq_AuwCACR5ztR0EGYSdS4xoCMTsQAvD_BwE">www.fuzionest.com</a>
                    <Link to={'privacy'}><a href="#">Privacy Policy</a></Link>
                    <Link to={'condition'}><a href="#">Teams and conditions</a></Link>
                </section>
                <section class="flex_content padding_1x">
                    <p>© 2024|| All rights reserved</p>
                </section>
            </footer>
        </>
    )
}
export default Footer;