import React from 'react';
import './grocery.css';
import order from './assets/iPhone 15 Pro Black Titanium Mockup Portrait.jpg'
import payment from './assets/payment.jpg'
import products from './assets/Products list.jpg'
import logo from './assets/PUMKEE  512 1.png'
import gif from './assets/Delivery Boy.gif'
import splash from './assets/splash.jpg'
import img1 from './assets/Android 1.jpg'
import img2 from './assets/Android 3.jpg'
import img3 from './assets/Android 4.jpg'
import img4 from './assets/Android 5.jpg'


function Grocery() {
    return (
        <>
            <section class="section-hero">


                <div class="hero">
                    <div class="hero-text-box">
                    <img className='logo' src={logo} alt="" loading="lazy" />
                        <h1 class="hero-description">
                          PUMKEE
                        </h1>
                        <h1 class="heading-primary">
                            A healthy Grocery delivered to your door, every single day
                        </h1>
                    </div>
                    <div class="hero-img-box">
                        <img
                            src={splash}
                            alt="Woman enjoying food, meals in storage container, and food bowls on a table"
                            class="hero-img"
                        />
                    </div>
                </div>
            </section>
            <main>
                <div class="divisions division_2 flex padding_2x">
                    <section class="flex_content padding_2x">
                        <figure>
                            <img src={img4} alt="" loading="lazy" />
                            <figcaption class="padding_1x">
                                <h2 class="title medium">What We Do?</h2>
                                <p>we're passionate about simplifying your grocery shopping experience. With our user-friendly app, we strive to make grocery shopping convenient, efficient, and enjoyable for everyone. </p>
                            </figcaption>
                        </figure>
                    </section>
                    <section class="flex_content cards padding_2x flex">
                        <figure class="flex card">
                            <img src={img1} alt="" loading="lazy" />
                            <figcaption class="padding_2x">
                                <h3 class="title small">Our Products</h3>
                                <p>we offer a wide range of high-quality products to meet all your grocery needs. From fresh produce to pantry staples, we've got everything you need to stock your kitchen and satisfy your cravings.</p>
                            </figcaption>
                        </figure>
                        <figure class="flex card">
                            <figcaption class="padding_2x">
                                <h3 class="title small">Order Activity</h3>
                                <p>Keep track of your purchases and delivery status with ease using Pumkee's Order Activity feature. We understand the importance of staying informed about your orders, which is why we provide real-time updates and detailed information every step of the way.</p>
                            </figcaption>
                            <img src={img2} alt="" loading="lazy" />
                        </figure>
                        <figure class="flex card">
                            <img src={img3} alt="" loading="lazy" />
                            <figcaption class="padding_2x">
                                <h3 class="title small">Delivery Tracking</h3>
                                <p>Monitor the progress of your deliveries in real-time with our convenient delivery tracking feature. Know exactly when your order is out for delivery and receive notifications when it's about to arrive. </p>
                            </figcaption>
                        </figure>
                        <figure class="flex card">
                            <figcaption class="padding_2x">
                                <h3 class="title small">Support</h3>
                                <p>we're committed to providing exceptional customer support to ensure that your shopping experience is as smooth and enjoyable as possible. Whether you have a question, need assistance, or want to provide feedback, our dedicated support team is here to help. </p>
                            </figcaption>
                            <img src={img4} alt="" loading="lazy" />
                        </figure>
                    </section>
                </div>

            </main>
        </>
    );
}

export default Grocery;